import {
  Alert,
  Avatar,
  Button,
  Chip,
  Divider,
  Link,
  Stack,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  styled,
  ListItem,
} from '@mui/material';
import React from 'react';
import { Icon } from '../Icon';
import { defaultTo, get } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VideoPreview } from './AdvancedSubflows/AdvancedCreative/VideoPreview';
import { CellContentStyledProps } from './types';

export const AdditionalOptionsTypographyStyled = styled(Typography)(
  ({ theme }) => ({
    display: 'inline-flex',
    flexDirection: 'row',
    gap: theme.spacing(1.25),
    alignItems: 'center',
    cursor: 'pointer',
    fontWeight: 700,
  })
);

export const AvatarStyled = styled(Avatar)({
  backgroundColor: '#d3d3d3',
});

export const AvatarImageStyled = styled('img')({
  width: '100%',
  height: 'auto',
  objectFit: 'fill',
  scale: 0.75,
});

export const DividerStyled = styled(Divider)(({ theme }) => ({
  '&.MuiDivider-root': {
    '&:before': {
      width: theme.spacing(2.25),
    },
  },
}));

export const DividerTypographyStyled = styled(Typography)({
  fontWeight: 700,
});

export const SectionBoxStyled = styled(Stack)(({ theme }) => ({
  paddingBottom: theme.spacing(5),
}));

export const SectionInnerBoxStyled = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3, 2.25, 0),
  gap: theme.spacing(4),
}));

export const CancelButtonStyled = styled(Button)(({ theme }) => ({
  color: theme.palette.blue[6],
  flex: 1,
}));

export const TableCellAdditionalDataBlockStyled = styled(Typography)(
  ({ theme }) => ({
    color: theme.palette.grey[3],
    fontSize: theme.spacing(1.25),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
  })
);

export const LinkStyled = styled(Link)(() => ({
  fontWeight: 700,
  fontSize: '0.875rem',
  gap: 4,
  display: 'inline-flex',
  alignItems: 'center',
  cursor: 'pointer',
}));

export const CellContentStyled = styled(Stack, {
  shouldForwardProp: prop => prop !== 'color' && prop !== 'featured',
})(({ theme, color, featured }: CellContentStyledProps) => ({
  '&::before': {
    content: '""',
    width: 4,
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    backgroundColor: featured
      ? get(theme?.palette, defaultTo(color, 'green.9'))
      : 'transparent',
  },
}));

export const CellContentVirtualizedStyled = styled(CellContentStyled)(
  ({ theme }) => ({
    padding: theme.spacing(2),
  })
);

export const ReactiveIconSmall = styled(Icon)(({ theme }) => ({
  width: theme.spacing(1.5),
  height: theme.spacing(1.5),
}));

export const ReactiveIconAlert = styled(Icon)(({ theme }) => ({
  paddingRight: theme.spacing(),
}));

export const AdvancedSectionIconStyled = styled(FontAwesomeIcon)(
  ({ theme }) => ({
    color: theme.palette.blue[6],
    cursor: 'pointer',
    width: theme.spacing(2.4375),
    height: theme.spacing(2.4375),
  })
);

export const RecommendedChipStyled = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.green[10],
  color: theme.palette.green[4],
  fontSize: 10,
}));

export const RecommendedAlertStyled = styled(Alert)(({ theme }) => ({
  color: theme.palette.green[2],
  fontSize: theme.spacing(2),
  lineHeight: 1.75,

  '& .MuiAlert-action': {
    paddingTop: 0,
  },
}));

export const VideoPreviewStyled = styled(VideoPreview)(({ theme }) => ({
  width: theme.spacing(15),
  height: theme.spacing(8.375),
  borderRadius: 0,
}));

export const ImagePreviewStyled = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(15),
  height: theme.spacing(8.375),
  borderRadius: 0,
}));

export const AudienceExtensionSwitchText = styled(Typography)(
  ({ theme }) => ({
    color: theme.palette.grey[3],
    fontSize: theme.spacing(2),
    fontWeight: 400,
  })
);

export const AudienceExtensionInfoIcon = styled(FontAwesomeIcon)(
  ({ theme }) => ({
    color: theme.palette.grey[3],
  })
);

export const AudienceExtensionTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.grey[2],
    width: theme.spacing(23),
    padding: theme.spacing(1.5),
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.grey[2],
  },
}));

export const GeneralItemTypographyStyled = styled(Typography)(
  ({ theme }) => ({
    fontWeight: theme.typography.fontWeightBold,
  })
);

export const GeneralListStyled = styled('ul')(() => ({
  listStyleType: 'disc',
  margin: 0,
}));

export const GeneralListItemStyled = styled('li')(() => ({
  padding: 0,
}));

export const SubListItemStyled = styled(ListItem)(({ theme }) => ({
  alignItems: 'flex-start',
  boxAlign: 'start',
  padding: theme.spacing(0, 1),
}));
