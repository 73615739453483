import React from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { classes } from '@components/containers/AdGroupsIndexPage/styles';
import { Link } from 'react-router-dom';
import { useAdGroupsTable } from '@components/containers/AdGroupsIndexPage/AdGroupsTableContext';
import { LineItem } from '@local-types';

export const AdGroupNameCellRenderer = (params: GridCellParams<LineItem>) => {
  const { campaignId } = useAdGroupsTable();
  const { name, id, url } = params.row;

  return (
    <Link
      className={classes.link}
      to={
        url?.includes('static')
          ? `/campaigns/${campaignId}/adgroups/${id}/displays`
          : `/campaigns/${campaignId}/adgroups/${id}/creative`
      }
    >
      {name}
    </Link>
  );
};
