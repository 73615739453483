import React, { useMemo } from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import {
  ActionMenuItem,
  DataGridActionMenuRenderer,
} from '@v2/components/ui/DataGrid/renderers/DataGridActionMenuRenderer';
import {
  faBoxArchive,
  faArrowRotateLeft,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCreativesTable } from '@components/Creatives/CreativesTableContext';

export const CreativeActionMenuCellRenderer = (
  params: Partial<GridCellParams>,
) => {
  const { handleArchiveUnarchiveCreative } = useCreativesTable();

  const { lineitem_set, archived, beeswax_crid } = params.row;

  const isDisabled = !beeswax_crid || (!archived && lineitem_set?.length > 0);

  const items = useMemo(() => {
    return [
      {
        label: archived ? 'Unarchive' : 'Archive',
        onClick: () => {
          handleArchiveUnarchiveCreative(params.row);
        },
        icon: (
          <FontAwesomeIcon
            icon={archived ? faArrowRotateLeft : faBoxArchive}
            fontSize="20px"
          />
        ),
        // If this creative is assigned to ad groups, return null
        disabled: isDisabled,
      },
    ].filter(Boolean);
  }, [params.row]) as ActionMenuItem[];

  return <DataGridActionMenuRenderer params={params} items={items} />;
};
