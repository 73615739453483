import PropTypes from 'prop-types';
import { StackProps, type Theme } from '@mui/material';
import React, { HTMLAttributes } from 'react';

export interface AudienceType {
  id: number;
  included?: boolean;
  featured?: boolean;
  audience_name: string;
  first_party_data: boolean;
  icon_url: string | null;
  info: string;
  private: boolean;
  third_party_data: boolean;
  type: string;
  url: string;
  cpm: string;
}

export const inventoryType = PropTypes.shape({
  key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.number,
  included: PropTypes.bool,
  featured: PropTypes.bool,
});

export const customInventoryType = PropTypes.shape({
  id: PropTypes.number,
  included: PropTypes.bool,
  featured: PropTypes.bool,
  adjusted_cpm: PropTypes.number,
  bundle_name: PropTypes.string,
  estimated_cpm: PropTypes.number,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  info: PropTypes.string,
  ott: PropTypes.bool,
  private: PropTypes.bool,
  type: PropTypes.string,
  url: PropTypes.string,
});

export interface CellContentStyledProps extends StackProps {
  featured?: boolean;
  color?: string;
  theme?: Theme;
}

export type DataListProps = (
  | {
      data: AudienceType[];
      title: string;
      render: (data: AudienceType[]) => React.ReactNode;
      singleSubField: true;
    }
  | {
      data: AudienceType[];
      title: string;
      singleSubField: undefined | false;
      render: (data: AudienceType, idx: number) => React.ReactNode;
    }
) &
  HTMLAttributes<HTMLUListElement>;
