import { Stack, Typography } from '@mui/material';
import { AudienceElement } from './AudienceElement';

import React from 'react';
import {
  AdditionalOptionsTypographyStyled,
  AdvancedSectionIconStyled,
  DividerStyled,
  DividerTypographyStyled,
  SectionBoxStyled,
  SectionInnerBoxStyled,
} from '../styles';
import { Copies, fields } from '../constants';
import { useAudience } from './hooks/useAudience';
import {
  faPen,
  faSquareArrowUpRight,
} from '@fortawesome/pro-regular-svg-icons';
import { DataList } from '../DataList';
import { map, slice } from 'lodash';
import { SectionInfo } from '@components/SectionInfo';
import { Controller } from 'react-hook-form';
import { useCopy } from '@hooks/copy';
import { AudienceProps } from './types';
import { PenStyled } from './styles';
import { AudienceType } from '../types';

export const Audience = ({
  campaignName,
  onAdvanced,
  info,
  isDisplay,
  ...props
}: AudienceProps) => {
  const Copy = useCopy(Copies);

  const { audience, control } = useAudience({
    campaignName,
    isDisplay,
  });

  return (
    <SectionBoxStyled {...props}>
      <DividerStyled textAlign="left">
        <DividerTypographyStyled>Audience</DividerTypographyStyled>
      </DividerStyled>
      <SectionInnerBoxStyled>
        <SectionInfo infoBlocks={info} />
        <Controller
          control={control}
          name={fields.social.path}
          render={({ field }) => <AudienceElement {...field} />}
        />
        <Stack spacing={1}>
          <AdditionalOptionsTypographyStyled onClick={onAdvanced}>
            <AdvancedSectionIconStyled icon={faSquareArrowUpRight} />
            {Copy.advancedTargetingOptionsLabel}
          </AdditionalOptionsTypographyStyled>
          {!!audience.length && (
            <DataList
              singleSubField
              title="Include:"
              data={audience}
              data-testid="advanced-audience-data-list"
              render={(d: AudienceType[]) => (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography variant="body2">
                    {map(slice(d, 0, 2), 'audience_name').join(', ')}{' '}
                    {d.length > 2 && `+${d.length - 2} more...`}
                  </Typography>
                  <PenStyled
                    role="button"
                    onClick={onAdvanced}
                    icon={faPen}
                  />
                </Stack>
              )}
            />
          )}
        </Stack>
      </SectionInnerBoxStyled>
    </SectionBoxStyled>
  );
};
