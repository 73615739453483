import React from 'react';
import { DataGrid } from '@v2/components/ui/DataGrid';
import { useAdGroupsTable } from '@components/containers/AdGroupsIndexPage/AdGroupsTableContext';
import { DateCellRenderer } from '@v2/components/ui/DataGrid/renderers/DateCellRenderer';
import { CurrencyCellRenderer } from '@v2/components/ui/DataGrid/renderers/CurrencyCellRenderer';
import { DotNotationValueGetter } from '@v2/components/ui/DataGrid/DotNotationValueGetter';
import { AdGroupStatusCellRenderer } from '@components/containers/AdGroupsIndexPage/renderers/AdGroupStatusCellRenderer';
import { AdGroupActionMenuCellRenderer } from '@components/containers/AdGroupsIndexPage/renderers/AdGroupActionMenuCellRenderer';
import { AdGroupSyncCheckRenderer } from '@components/containers/AdGroupsIndexPage/renderers/AdGroupSyncCheckRenderer';
import { defaultTheme_v2 } from '@themes/default_v2';
import { ThemeProvider } from '@mui/material';
import { AdGroupNameCellRenderer } from '@components/containers/AdGroupsIndexPage/renderers/AdGroupNameCellRenderer';
import { useFlags as useLDFlags } from 'launchdarkly-react-client-sdk';
import { AdGroupRealtimeDeliveryCellRenderer } from '@components/containers/AdGroupsIndexPage/renderers/AdGroupRealtimeDeliveryCellRenderer';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { PinnedPosition } from '@v2/components/ui/DataGrid/constants';

export const AdGroupsTableV2 = () => {
  const {
    sortedAdGroups,
    isLoading,
    paginationModel,
    setPaginationModel,
    sortModel,
    setSortModel,
    rowCount,
  } = useAdGroupsTable();
  const { realtimeDeliveryModal, releaseSyncCheckColumn } = useLDFlags();

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 60,
      pinned: PinnedPosition.LEFT,
      resizable: false,
      hideable: false,
    },
    {
      field: 'name',
      headerName: 'Ad Group',
      width: 280,
      pinned: PinnedPosition.LEFT,
      renderCell: AdGroupNameCellRenderer,
      resizable: false,
      hideable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: AdGroupStatusCellRenderer,
      width: 100,
      pinned: PinnedPosition.LEFT,
      resizable: false,
      hideable: false,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    realtimeDeliveryModal && {
      field: 'realtime_delivery',
      headerName: '',
      width: 60,
      pinned: PinnedPosition.LEFT,
      renderCell: AdGroupRealtimeDeliveryCellRenderer,
      resizable: false,
      hideable: false,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'actions',
      headerName: '',
      width: 50,
      renderCell: AdGroupActionMenuCellRenderer,
      pinned: PinnedPosition.LEFT,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'campaign_name',
      headerName: 'Campaign',
      width: 250,
    },
    {
      field: 'start_date',
      headerName: 'Start Date',
      minWidth: 120,
      renderCell: DateCellRenderer,
    },
    {
      field: 'end_date',
      headerName: 'End Date',
      minWidth: 120,
      renderCell: (params: GridCellParams) =>
        // eslint-disable-next-line new-cap
        DateCellRenderer(params, 'No end date'),
    },
    {
      field: 'cpm',
      headerName: 'Max Bid',
      renderCell: CurrencyCellRenderer,
    },
    {
      field: 'stats_cache.total_spend',
      headerName: 'Spend',
      valueGetter: DotNotationValueGetter,
      renderCell: CurrencyCellRenderer,
    },
    {
      field: 'stats_cache.budget_remaining',
      headerName: 'Budget Remaining',
      minWidth: 160,
      valueGetter: DotNotationValueGetter,
      renderCell: CurrencyCellRenderer,
    },
    {
      field: 'stats_cache.visit_count',
      headerName: 'Visits',
      valueGetter: DotNotationValueGetter,
    },
    {
      field: 'stats_cache.cost_per_visit',
      headerName: 'Cost per Visit',
      valueGetter: DotNotationValueGetter,
      renderCell: CurrencyCellRenderer,
      minWidth: 120,
    },
    releaseSyncCheckColumn && {
      field: 'ad_server_sync_status',
      headerName: 'Ad Server Check',
      renderCell: AdGroupSyncCheckRenderer,
      minWidth: 120,
      sortable: false,
    },
  ].filter(Boolean);

  return (
    <ThemeProvider theme={defaultTheme_v2}>
      <DataGrid
        loading={isLoading}
        columns={columns}
        rows={sortedAdGroups}
        getRowId={(row: { id: string }) => row.id}
        slotProps={{
          row: {
            'data-testid': 'adgroup-row',
          },
        }}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        rowCount={rowCount ?? 0}
        disableRowSelectionOnClick
      />
    </ThemeProvider>
  );
};
