import React, { useMemo } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { DataGrid } from '@v2/components/ui/DataGrid/DataGrid';
import { defaultTheme_v2 } from '@themes/default_v2';
import { DateCellRenderer } from '@v2/components/ui/DataGrid/renderers/DateCellRenderer';
import { DotNotationValueGetter } from '@v2/components/ui/DataGrid/DotNotationValueGetter';
import { CurrencyCellRenderer } from '@v2/components/ui/DataGrid/renderers/CurrencyCellRenderer';
import { CreativeNameCellRenderer } from './renderers/CreativeNameCellRenderer';
import { CreativeStatusCellRenderer } from '@components/containers/CreativeIndexPage/renderers/CreativeStatusCellRenderer';
import { Scopes } from '@constants/scopes';
import { usePermissions } from '@components/hooks/permissions';
import { CreativeQCAlerts } from '@components/CreativeQCAlerts';
import { CreativeAdGroupCellRenderer } from './renderers/CreativeAdGroupCellRenderer';
import { GridColDef } from '@mui/x-data-grid-pro';
import { PinnedPosition } from '@v2/components/ui/DataGrid/constants';
import { CreativeActionMenuCellRenderer } from '@components/Creatives/renderers/CreativeActionMenuCellRender';
import {
  CreativesTableContext,
  CreativesTableContextProvider,
} from '@components/Creatives/CreativesTableContext';

export const CreativesTableV2 = () => {
  const { hasPermission } = usePermissions();
  const showQCFunctionality = hasPermission([Scopes.CAN_VIEW_QC]);

  const columns = useMemo(
    () =>
      [
        {
          field: 'id',
          headerName: 'ID',
          width: 60,
          resizable: false,
          hideable: false,
          pinned: PinnedPosition.LEFT,
        },
        {
          field: 'name',
          headerName: 'Name',
          renderCell: CreativeNameCellRenderer,
          width: 220,
          resizable: false,
          hideable: false,
          pinned: PinnedPosition.LEFT,
        },
        {
          field: 'active',
          headerName: 'Status',
          renderCell: CreativeStatusCellRenderer,
          width: 180,
          resizable: false,
          hideable: false,
          pinned: PinnedPosition.LEFT,
        },
        {
          field: 'action',
          headerName: '',
          width: 50,
          renderCell: CreativeActionMenuCellRenderer,
          pinned: PinnedPosition.LEFT,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
        },
        {
          field: 'adGroup',
          headerName: 'Ad Group',
          flex: 1,
          renderCell: CreativeAdGroupCellRenderer,
          resizable: false,
          hideable: false,
        },
        {
          field: 'media_upload_date',
          headerName: 'Uploaded',
          renderCell: DateCellRenderer,
          minWidth: 150,
          resizable: false,
          hideable: false,
        },
        {
          field: 'stats.total_spend',
          headerName: 'Spend',
          valueGetter: DotNotationValueGetter,
          renderCell: CurrencyCellRenderer,
          minWidth: 120,
          resizable: false,
          hideable: false,
        },
        {
          field: 'stats.visit_count',
          headerName: 'Visits',
          valueGetter: DotNotationValueGetter,
          minWidth: 120,
          resizable: false,
          hideable: false,
        },
        {
          field: 'stats.cost_per_visit',
          headerName: 'Cost Per Visit',
          valueGetter: DotNotationValueGetter,
          renderCell: CurrencyCellRenderer,
          minWidth: 120,
          resizable: false,
          hideable: false,
        },
      ].filter(Boolean) as GridColDef[],
    [],
  );

  return (
    <ThemeProvider theme={defaultTheme_v2}>
      <CreativesTableContextProvider>
        <CreativesTableContext.Consumer>
          {({
            paginationModel,
            setPaginationModel,
            count,
            creatives,
            isLoading,
          }) => (
            <>
              {showQCFunctionality && (
                <CreativeQCAlerts
                  email={'support@tvscientific.com'}
                  items={creatives || []}
                />
              )}
              <DataGrid
                paginationMode="server"
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                loading={isLoading}
                rows={creatives}
                rowCount={count}
                columns={columns}
                disableRowSelectionOnClick
              />
            </>
          )}
        </CreativesTableContext.Consumer>
      </CreativesTableContextProvider>
    </ThemeProvider>
  );
};
