import React, { createContext, useContext, useMemo, useState } from 'react';
import { Campaign, LineItem } from '@local-types';
import { useFlags } from '@hooks/flags';
import { getStatus } from '@components/containers/AdGroupsIndexPage/getStatus';
import { useSort } from '@hooks/sort';
import RealtimeDeliveryModal from '@components/containers/AdGroupsIndexPage/renderers/RealtimeDeliveryModal';
import ModalContext from '@providers/ModalContext';
import { GridSortModel } from '@mui/x-data-grid-pro';
import { GROUP_TYPE, useGroups } from '@components/hooks/apis/groups';

export interface AdGroupsTableContextProviderProps {
  campaign: Campaign;
  campaignId: number;
  statuses: number[];
  handleManageDisplay: (display: LineItem) => void;
  handleManageAdGroup: (adGroup: LineItem) => void;
  handleDuplicateAdGroup: (adGroup: LineItem) => void;
  handleOpenWeighting: (adGroup: LineItem) => void;
  handleIsDuplicatable: (adGroup: LineItem) => boolean;
  handleArchiveUnarchiveAdGroup: (adGroup: LineItem) => void;
  handleOpenBid: (adGroup: LineItem) => void;
  v2Endpoint: boolean;
  children: React.ReactNode;
}

export interface AdGroupsTableContextType
  extends Omit<AdGroupsTableContextProviderProps, 'children'> {
  rowCount: number;
  isLoading: boolean;
  paginationModel: {
    page: number;
    pageSize: number;
  };
  setPaginationModel: (paginationModel: {
    page: number;
    pageSize: number;
  }) => void;
  sortModel: GridSortModel;
  setSortModel: (sortModel: GridSortModel) => void;
  sortedAdGroups: LineItem[];
  handlePauseActive: (adGroup: LineItem) => void;
  handleOpenRealTimeModal: (
    event: React.MouseEvent<HTMLElement>,
    adGroup: LineItem
  ) => void;
}

export const AdGroupsTableContext = createContext<AdGroupsTableContextType>({
  campaign: {} as Campaign,
  campaignId: 0,
  rowCount: 0,
  statuses: [],
  sortedAdGroups: [],
  isLoading: false,
  paginationModel: {
    page: 0,
    pageSize: 25,
  },
  sortModel: [
    {
      field: 'name',
      sort: 'asc',
    },
  ],
  v2Endpoint: false,
  setSortModel: () => {},
  setPaginationModel: () => {},
  handleManageDisplay: () => {},
  handleManageAdGroup: () => {},
  handleDuplicateAdGroup: () => {},
  handleOpenWeighting: () => {},
  handleIsDuplicatable: () => false,
  handleOpenBid: () => {},
  handlePauseActive: () => {},
  handleOpenRealTimeModal: () => {},
  handleArchiveUnarchiveAdGroup: () => {},
});

export const AdGroupsTableContextProvider = ({
  children,
  campaignId,
  campaign,
  statuses,
  v2Endpoint,
  ...rest
}: AdGroupsTableContextProviderProps) => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const { flags, Flags } = useFlags();
  const isSyncEnabled = flags[Flags.SYNC_CHECKS_ENABLED];
  const { setModal } = useContext(ModalContext);

  const { order, orderBy, getComparator, stableSort } = useSort();

  const {
    items,
    update,
    mutate: refreshAdGroups,
    isLoading: isLoadingLineItems,
  } = useGroups(GROUP_TYPE.AD_GROUP, campaignId, {
    params: {
      sync: isSyncEnabled ? 1 : null,
      ordering: '-active,-pending_active,-draft,archived,-start_date',
    },
  });

  const { items: staticItems, isLoading: isLoadingDisplays } = useGroups(
    GROUP_TYPE.STATIC_GROUP,
    campaignId,
    {
      params: {
        sync: isSyncEnabled ? 1 : null,
      },
    }
  );

  const sortedAdGroups = useMemo(() => {
    return stableSort(
      [...items, ...staticItems] as never[],
      getComparator(order, orderBy)
    )
      .map(lineItem => {
        return {
          ...lineItem,
          campaign_name: campaign.name,
          status: getStatus(
            lineItem.active,
            lineItem.pending_active,
            lineItem.draft,
            lineItem.archived
          ),
        } as LineItem;
      })
      .filter(lineItem => {
        return lineItem.status && statuses.includes(lineItem.status);
      })
      .reduce((unique, lineItem) => {
        if (!unique.some(c => c.id === lineItem.id)) {
          unique.push(lineItem);
        }
        return unique;
      }, [] as LineItem[]);
  }, [items, staticItems, order, orderBy, statuses, campaign]);

  const handlePauseActive = async (adGroup: LineItem) => {
    await update({ id: adGroup.id, active: !adGroup.active });
    refreshAdGroups();
  };

  const handleOpenRealTimeModal = (
    event: React.MouseEvent<HTMLElement>,
    adGroup: LineItem
  ) => {
    event.preventDefault();

    setModal({
      isOpen: true,
      component: RealtimeDeliveryModal,
      data: {
        id: adGroup.beeswax_lid,
        description: `${adGroup.name} (${adGroup.id})`,
      },
    });
  };

  return (
    <AdGroupsTableContext.Provider
      value={{
        ...rest,
        campaignId,
        campaign,
        statuses,
        v2Endpoint,
        sortedAdGroups,
        handlePauseActive,
        handleOpenRealTimeModal,
        rowCount: sortedAdGroups?.length ?? 0,
        isLoading: isLoadingLineItems || isLoadingDisplays,
        paginationModel,
        setPaginationModel,
        sortModel,
        setSortModel,
      }}
    >
      {children}
    </AdGroupsTableContext.Provider>
  );
};

export const useAdGroupsTable = () => {
  return useContext(AdGroupsTableContext);
};
