import React from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { useAdGroupsTable } from '@components/containers/AdGroupsIndexPage/AdGroupsTableContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartSimple } from '@fortawesome/pro-solid-svg-icons';
import { IconButton } from '@mui/material';
import { LineItem } from '@local-types';

export const AdGroupRealtimeDeliveryCellRenderer = (
  params: GridCellParams<LineItem>
) => {
  const { handleOpenRealTimeModal } = useAdGroupsTable();
  const { beeswax_lid } = params.row;


  return (
    <IconButton
      disabled={!beeswax_lid}
      color="secondary"
      aria-label="open the realtime delivery graph modal"
      onClick={event => handleOpenRealTimeModal(event, params.row)}
    >
      <FontAwesomeIcon icon={faChartSimple} fontSize="18" />
    </IconButton>
  );
};
