import React from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { Chip, useTheme } from '@mui/material';
import { Statuses } from '@components/containers/CampaignsPage/statuses';
import { Campaign } from '@local-types';

export const CampaignStatusCellRenderer = (
  params: GridCellParams<Campaign, string | number>,
) => {
  const theme = useTheme();
  const { palette } = theme;
  if (params.value === null || params.value === undefined) {
    return null;
  }

  let status = params.value;
  if (typeof params.value !== 'number') {
    status = parseInt(params.value as string, 10);
  }

  switch (status) {
    case Statuses.DRAFT:
      return (
        <Chip
          label="Draft"
          data-testid="draft-campaign"
          sx={{
            width: '100%',
            color: palette.blue?.['6'],
            background: palette.blue?.['10'],
          }}
        />
      );
    case Statuses.ACTIVE:
      return (
        <Chip
          label="Active"
          data-testid="active-campaign"
          sx={{
            width: '100%',
            color: palette.green?.['6'],
            background: palette.green?.['10'],
          }}
        />
      );
    case Statuses.PAUSED:
      return (
        <Chip
          label="Paused"
          data-testid="paused-campaign"
          sx={{
            width: '100%',
            color: palette.red?.['1'],
            background: palette.red?.['5'],
          }}
        />
      );
      case Statuses.ARCHIVED:
        return (
          <Chip
            label="Archived"
            data-testid="archived-campaign"
            sx={{
              width: '100%',
              color: '#77858c',
              background: '#EDF1F2'
            }}
          />
        );
  }
};
