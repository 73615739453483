import React, { useMemo } from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import {
  FileCopyOutlined,
  OndemandVideo,
  PeopleOutlineOutlined,
  EditOutlined,
} from '@mui/icons-material';
import AspectRatioOutlinedIcon from '@mui/icons-material/AspectRatioOutlined';
import { classes } from '@components/containers/AdGroupsIndexPage/styles';
import { useAdGroupsTable } from '@components/containers/AdGroupsIndexPage/AdGroupsTableContext';
import { renderStatus } from '@components/containers/AdGroupsIndexPage/AdGroupsTable';
import { LineItem } from '@local-types';
import { Statuses } from '@components/containers/AdGroupsIndexPage/statuses';
import { useFlags } from '@components/hooks';
import { renderArchiveUnarchiveStatus } from '@components/containers/AdGroupsIndexPage/utils';

import {
  ActionMenuItem,
  DataGridActionMenuRenderer,
} from '@v2/components/ui/DataGrid/renderers/DataGridActionMenuRenderer';

export const AdGroupActionMenuCellRenderer = (
  params: GridCellParams<LineItem>,
) => {
  const { url, is_control_group, active, status } = params.row;
  const {
    handleManageDisplay,
    handleManageAdGroup,
    handleIsDuplicatable,
    handleDuplicateAdGroup,
    handleOpenWeighting,
    handleOpenBid,
    handlePauseActive,
    handleArchiveUnarchiveAdGroup,
  } = useAdGroupsTable();

  const { flags } = useFlags();
  const canArchive =
    flags.USER_CAN_ARCHIVE &&
    (status === Statuses.PAUSED || status === Statuses.ARCHIVED);

  const isDisplay = url.includes('static');

  const items = useMemo(() => {
    return [
      isDisplay && {
        id: 'manage',
        label: 'Manage Ad Group',
        onClick: () => handleManageDisplay(params.row),
        icon: (
          <AspectRatioOutlinedIcon
            fontSize="small"
            className={classes.menuIcon}
          />
        ),
      },
      !isDisplay &&
        !is_control_group &&
        status !== Statuses.ARCHIVED && {
          id: 'manage',
          label: 'Manage Ad Group',
          onClick: () => handleManageAdGroup(params.row),
          icon: <PeopleOutlineOutlined fontSize="small" />,
        },
      !isDisplay &&
        !is_control_group &&
        status !== Statuses.DRAFT && {
          id: 'duplicate',
          label: 'Duplicate Ad Group',
          onClick: () => handleDuplicateAdGroup(params.row),
          icon: <FileCopyOutlined fontSize="small" />,
          disabled: handleIsDuplicatable(params.row),
        },
      !isDisplay &&
        status !== Statuses.ARCHIVED && {
          id: 'weighting',
          label: 'Edit Weighting',
          onClick: () => handleOpenWeighting(params.row),
          icon: <OndemandVideo fontSize="small" />,
        },
      !isDisplay &&
        !is_control_group &&
        status !== Statuses.DRAFT &&
        status !== Statuses.ARCHIVED && {
          id: 'bid',
          label: 'Edit Bid',
          onClick: () => handleOpenBid(params.row),
          icon: <EditOutlined fontSize="small" />,
        },
      !isDisplay &&
        !is_control_group &&
        (status === Statuses.ACTIVE || status === Statuses.PAUSED) && {
          id: 'pause',
          label: renderStatus(active, 'Ad Group'),
          onClick: () => handlePauseActive(params.row),
        },
      !isDisplay &&
        canArchive && {
          id: 'pause',
          label: renderArchiveUnarchiveStatus(status!, 'Ad Group'),
          onClick: () => handleArchiveUnarchiveAdGroup(params.row),
        },
    ].filter(Boolean);
  }, [
    isDisplay,
    canArchive,
    is_control_group,
    status,
    params.row,
  ]) as ActionMenuItem[];

  return <DataGridActionMenuRenderer params={params} items={items} />;
};
